import React, { FC, useRef } from 'react'
import PageTitle from 'src/components/ui/title/H2'
import Subtitle from 'src/components/ui/Subtitle'
import { motion, AnimatePresence } from 'framer-motion'
import { sliderAnimations } from 'src/components/sections/caseStudies/slider/animations'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import Button from 'src/components/ui/Button'

export interface ISlideTranslatedContent {
  subtitle: string
  firstPartOfTitle: string
  secondPartOfTitle: string
  content: string
  link: string
  image: {
    url: string
    alt: string
  }
}

interface ISlideProps {
  className?: string
  translatedContent: ISlideTranslatedContent
  selectedSlideIndex: number
  slidesNavDesktopRef: React.MutableRefObject<HTMLDivElement>
  isBeforeEnterAnimation: boolean
}

const animationVariants = {
  enter: {
    zIndex: 0,
    opacity: 0,
  },
  center: {
    zIndex: 1,
    opacity: 1,
    y: 0,
  },
  exit: {
    zIndex: 0,
    opacity: 0,
  },
}

const Slide: FC<ISlideProps> = ({
  className,
  translatedContent,
  selectedSlideIndex,
  slidesNavDesktopRef,
  isBeforeEnterAnimation,
}) => {
  const subtitleRef = useRef<HTMLDivElement>(null)
  const largeTitleRef = useRef<HTMLDivElement>(null)
  const middleTittleRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const imgRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  const buttonRefDesktop = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('side-menu')

  sliderAnimations(
    subtitleRef,
    largeTitleRef,
    middleTittleRef,
    contentRef,
    imgRef,
    slidesNavDesktopRef,
    buttonRef,
    buttonRefDesktop
  )

  return (
    <div className="relative w-full">
      <AnimatePresence initial={false} exitBeforeEnter>
        <motion.div
          key={selectedSlideIndex}
          className={`${className} flex flex-wrap`}
          variants={animationVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: 0.5, type: 'tween' }}
        >
          <div className="w-full lg:w-1/2">
            <Subtitle
              ref={subtitleRef}
              className={`mb-4 lg:mb-6 ${
                isBeforeEnterAnimation ? 'opacity-0' : ''
              }`}
            >
              {t('our projects')}
            </Subtitle>
            <PageTitle
              className={`${isBeforeEnterAnimation ? 'opacity-0' : ''}`}
              ref={largeTitleRef}
              firstPart={translatedContent.firstPartOfTitle}
              secondPart={translatedContent.secondPartOfTitle}
            />
            <h5
              ref={middleTittleRef}
              className={`mt-12 text-2xl text-red font-palanquinDark font-semibold ${
                isBeforeEnterAnimation ? 'opacity-0' : ''
              }`}
            >
              {translatedContent.subtitle}
            </h5>
            <p
              ref={contentRef}
              className={`mt-4 hidden lg:block font-semibold ${
                isBeforeEnterAnimation ? 'opacity-0' : ''
              }`}
            >
              {translatedContent.content}
            </p>
            <div
              className={`mt-12 hidden md:block ${
                isBeforeEnterAnimation ? 'opacity-0' : ''
              }`}
              ref={buttonRefDesktop}
            >
              <Link to={translatedContent.link} className="inline-block">
                <Button>{t('read the whole case study')}</Button>
              </Link>
            </div>
          </div>
          <div className="lg:w-5/12 2xl:w-1/2 flex mt-12 md:mt-0 border-box w-full relative">
            <div className="w-full" style={{ paddingTop: '90%' }} />
            <div
              className={`${
                isBeforeEnterAnimation ? 'opacity-0' : ''
              }  w-full absolute inset-0 overflow-hidden pr-32 lg:pr-8 text-center`}
              ref={imgRef}
            >
              <img
                src={translatedContent.image.url}
                className="max-h-full inline-block"
                alt={translatedContent.image.alt}
              />
            </div>
          </div>
          <div
            className={`block md:hidden w-full text-center ${
              isBeforeEnterAnimation ? 'opacity-0' : ''
            }`}
            ref={buttonRef}
          >
            <Link to={translatedContent.link} className="inline-block">
              <Button>{t('read the whole case study')}</Button>
            </Link>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

Slide.defaultProps = {
  className: '',
}

export default Slide
