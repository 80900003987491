import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, useRef, MutableRefObject } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'
import { createShowFromBottomAnimation } from 'src/helpers/animations'

gsap.registerPlugin(ScrollTrigger)

export const iconsInRowAnimations = (
  subtitleRef: MutableRefObject<HTMLDivElement>,
  largeTitleRef: MutableRefObject<HTMLDivElement>,
  iconsRef: MutableRefObject<HTMLDivElement>
) => {
  const isLarge = useIsLargeScreen()
  const timeline = useRef(gsap.timeline())

  const animations = () => {
    const icons = Array.from(
      iconsRef.current.getElementsByClassName('single-icon')
    )

    createShowFromBottomAnimation(timeline.current, subtitleRef.current)
    createShowFromBottomAnimation(timeline.current, largeTitleRef.current)

    icons.forEach((icon) => {
      createShowFromBottomAnimation(timeline.current, icon)
    })

    ScrollTrigger.sort()
  }

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    animations()
  }, [isLarge])
}

export default {}
