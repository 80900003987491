// i18Moxly-extract-mark-ns-start main-page

import * as React from 'react'
import Layout from 'src/components/layout'
import { graphql } from 'gatsby'
import Hero from 'src/components/sections/hero'
import TeaserWithHighlight from 'src/components/sections/teaser/TeaserWithHighlight'
import CaseStudiesSlider from 'src/components/sections/caseStudies/slider'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import TrusedBy from 'src/components/sections/TrustedBy'
import IconsInRow from 'src/components/sections/icons-in-row'
import ContactSection from 'src/components/sections/ContactSection'
import RosaSection from 'src/components/sections/rosa'

const IndexPage = ({ data }) => {
  const translation = data.directus.pages[0].translations[0]
  if (!translation) {
    return <h1>Missing translation</h1>
  }

  const cmsJson = JSON.parse(translation.content)

  const { t } = useTranslation('main-page')

  return (
    <Layout title={cmsJson.title} metas={cmsJson.metas}>
      <Hero translationContent={cmsJson.firstSection} showBadges />
      <TeaserWithHighlight
        isImageSnapped
        translationContent={cmsJson.whoWeAreSection}
      />
      <IconsInRow translationContent={cmsJson.threeIconsSection} />
      <TeaserWithHighlight
        isImageSnapped
        translationContent={cmsJson.sportSection}
      />
      <RosaSection translationContent={cmsJson.rosaSection} />
      <CaseStudiesSlider
        slides={[
          {
            name: t('VAR system'),
            translatedContent: {
              subtitle: t('VAR system subtitle'),
              firstPartOfTitle: t('VAR system title'),
              secondPartOfTitle: t('VAR system title part 2'),
              content: t('VAR system content'),
              image: {
                url: t('VAR system-image'),
                alt: 'VAR system case study image',
              },
              link: t('VAR system link'),
            },
          },
          {
            name: t('IFAB'),
            translatedContent: {
              subtitle: t('IFAB'),
              firstPartOfTitle: t('Laws of the game'),
              secondPartOfTitle: t('In your pocket'),
              content: t('the-ifab-slide-content'),
              image: {
                url: t('the-ifab-image'),
                alt: 'The IFAB case study image',
              },
              link: t('IFAB link'),
            },
          },
          {
            name: t('E-learning'),
            translatedContent: {
              subtitle: t('E-learning subtitle'),
              firstPartOfTitle: t('E-learning title'),
              secondPartOfTitle: t('E-learning title part 2'),
              content: t('E-learning content'),
              image: {
                url: t('E-learning-image'),
                alt: 'E-learning case study image',
              },
              link: t('E-learning system link'),
            },
          },
          {
            name: t('Northfish'),
            translatedContent: {
              subtitle: t('northfish subtitle'),
              firstPartOfTitle: t('northfish title'),
              secondPartOfTitle: t('northfish title part 2'),
              content: t('northfish content'),
              image: {
                url: t('northfish-image'),
                alt: 'Northfish case study image',
              },
              link: t('northfish system link'),
            },
          },
          {
            name: t('Moxly'),
            translatedContent: {
              subtitle: t('Moxly subtitle'),
              firstPartOfTitle: t('Moxly title'),
              secondPartOfTitle: t('Moxly title part 2'),
              content: t('Moxly content'),
              image: {
                url: t('Moxly-image'),
                alt: 'Moxly case study image',
              },
              link: t('Moxly link'),
            },
          },
          {
            name: t('adidas'),
            translatedContent: {
              subtitle: t('adidas subtitle'),
              firstPartOfTitle: t('adidas title'),
              secondPartOfTitle: t('adidas title part 2'),
              content: t('adidas content'),
              image: {
                url: t('adidas-image'),
                alt: 'adidas case study image',
              },
              link: t('adidas system link'),
            },
          },
          {
            name: t('iMall'),
            translatedContent: {
              subtitle: t('iMall subtitle'),
              firstPartOfTitle: t('iMall title'),
              secondPartOfTitle: t('iMall title part 2'),
              content: t('iMall content'),
              image: {
                url: t('iMall-image'),
                alt: 'iMall case study image',
              },
              link: t('iMall system link'),
            },
          },
          {
            name: t('T-Mobile'),
            translatedContent: {
              subtitle: t('T-Mobile subtitle'),
              firstPartOfTitle: t('T-Mobile title'),
              secondPartOfTitle: t('T-Mobile title part 2'),
              content: t('T-Mobile content'),
              image: {
                url: t('T-Mobile-image'),
                alt: 'T-Mobile case study image',
              },
              link: t('T-Mobile system link'),
            },
          },
          {
            name: t('LOT'),
            translatedContent: {
              subtitle: t('LOT subtitle'),
              firstPartOfTitle: t('LOT title'),
              secondPartOfTitle: t('LOT title part 2'),
              content: t('LOT content'),
              image: {
                url: t('LOT-image'),
                alt: 'LOT case study image',
              },
              link: t('LOT system link'),
            },
          },
          {
            name: t('Jean Louis David'),
            translatedContent: {
              subtitle: t('Jean Louis David subtitle'),
              firstPartOfTitle: t('Jean Louis David title part 1'),
              secondPartOfTitle: t('Jean Louis David title part 2'),
              content: t('Jean Louis David content'),
              image: {
                url: t('jld-image'),
                alt: 'Jean Louis David case study image',
              },
              link: t('Jean Louis David system link'),
            },
          },
        ]}
      />
      <TrusedBy />
      <ContactSection />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    directus {
      pages(filter: { slug: { _eq: "/" } }) {
        translations(filter: { languages_code: { code: { _eq: $language } } }) {
          content
        }
      }
    }
  }
`

export default IndexPage
