import React, { FC, useState, useRef } from 'react'
import Slide, {
  ISlideTranslatedContent,
} from 'src/components/sections/caseStudies/slider/Slide'
import SlidesNavigation from 'src/components/sections/caseStudies/slider/SlidesNavigation'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

const StyledSlidesNavigation = styled(SlidesNavigation)`
  transform: translateY(-50%);
`
export interface ISlide {
  translatedContent: ISlideTranslatedContent
  name: string
}

interface ICaseStudiesSliderProps {
  className?: string
  slides: ISlide[]
}

const CaseStudiesSlider: FC<ICaseStudiesSliderProps> = React.memo(
  ({ className, slides }) => {
    const [selectedSlideIndex, setSelectedSlideIndex] = useState(0)
    const slidesNavDesktopRef = useRef<HTMLDivElement>(null)
    const [isBeforeEnterAnimation, setIsBeforeMainAnimation] = useState(true)
    const { t } = useTranslation('main-page')

    const selectSlideByIndex = (index: number) => {
      setIsBeforeMainAnimation(false)
      setSelectedSlideIndex(index)
    }

    return (
      <div
        data-name={t('our projects')}
        id={t('our projects')}
        className="relative py-10 md:py-20"
      >
        <section className={`${className} container flex`}>
          <Slide
            className=""
            translatedContent={slides[selectedSlideIndex].translatedContent}
            slidesNavDesktopRef={slidesNavDesktopRef}
            selectedSlideIndex={selectedSlideIndex}
            slides={slides}
            isBeforeEnterAnimation={isBeforeEnterAnimation}
            selectSlideByIndex={selectSlideByIndex}
          />
          <StyledSlidesNavigation
            ref={slidesNavDesktopRef}
            className="absolute right-0 md:right-10 top-1/2 opacity-0 z-10"
            selectedSlideIndex={selectedSlideIndex}
            slides={slides}
            selectSlideByIndex={selectSlideByIndex}
          />
        </section>
      </div>
    )
  }
)

CaseStudiesSlider.defaultProps = {
  className: '',
}

export default CaseStudiesSlider
