import React, { FC, useRef } from 'react'
import Subtitle from 'src/components/ui/Subtitle'
import H2 from 'src/components/ui/title/H2'
import { iconsInRowAnimations } from 'src/components/sections/icons-in-row/animations'
import emphasizeAmpersand from 'src/helpers/emphasizeAmpersand'

interface ISingleIcon {
  image: {
    url: string
    alt: string
  }
  firstPartOfTitle: string
  secondPartOfTitle: string
  content: string
}

export interface IIconsInRowProps {
  translationContent: {
    icons: ISingleIcon[]
    firstPartOfTitle: string
    secondPartOfTitle: string
    subtitle: string
    sectionName: string
  }
}

const mappedIconsToComponents = (icons: ISingleIcon[]) =>
  icons.map(({ image, firstPartOfTitle, secondPartOfTitle, content }) => (
    <div
      key={firstPartOfTitle}
      className="single-icon opacity-0 text-center px-2 md:px-8 mb-12 md:mb-0 md:flex-shrink-0 md:w-1/3"
    >
      <div className="flex flex-col items-center md:flex-wrap">
        <div className="w-full">
          <img
            src={image.url}
            alt={image.alt}
            style={{ maxWidth: '144px' }}
            className="mx-auto"
          />
        </div>
        <div className="text-center w-full">
          <h4 className="text-lg md:text-2xl font-palanquinDark">
            {firstPartOfTitle}
          </h4>
          <h4 className="text-lg md:text-2xl font-palanquinDark font-semibold">
            {emphasizeAmpersand(`& ${secondPartOfTitle}`, 1.1)}
          </h4>
        </div>
      </div>
      <p className="mt-6 text-xs md:text-base leading-18px md:leading-normal font-medium">
        {content}
      </p>
    </div>
  ))

const IconsInRow: FC<IIconsInRowProps> = ({
  translationContent: {
    icons,
    subtitle,
    firstPartOfTitle,
    secondPartOfTitle,
    sectionName,
  },
}) => {
  const subtitleRef = useRef<HTMLDivElement>(null)
  const largeTitleRef = useRef<HTMLDivElement>(null)
  const iconsRef = useRef<HTMLDivElement>(null)

  iconsInRowAnimations(subtitleRef, largeTitleRef, iconsRef)

  return (
    <section
      data-name={sectionName}
      id={sectionName}
      className="container py-10 md:py-20"
    >
      <div>
        <Subtitle
          ref={subtitleRef}
          className="mb-2 md:mb-6 text-right opacity-0"
        >
          {subtitle}
        </Subtitle>
        <H2
          ref={largeTitleRef}
          className="text-right opacity-0"
          firstPart={firstPartOfTitle}
          secondPart={secondPartOfTitle}
        />
        <div
          ref={iconsRef}
          className="flex items-between mt-10 md:mt-24 flex-wrap md:flex-nowrap"
        >
          {mappedIconsToComponents(icons)}
        </div>
      </div>
    </section>
  )
}

export default IconsInRow
