import React, { forwardRef } from 'react'
import { ISlide } from 'src/components/sections/caseStudies/slider'

interface ISlidesNavigationProps {
  className?: string
  slides: ISlide[]
  selectedSlideIndex: number
  selectSlideByIndex: (index: number) => void
}

const SlidesNavigation = forwardRef<HTMLHeadingElement, ISlidesNavigationProps>(
  ({ className, slides, selectedSlideIndex, selectSlideByIndex }, ref) => {
    const Indicator = (isActive) => (
      <div
        className={`ml-2 font-semibold w-4 h-0.5 transition-all ease-in-out duration-500 rounded-full ${
          isActive ? 'bg-red' : ''
        }`}
      />
    )

    const handleSlideChange = (index: number) => {
      selectSlideByIndex(index)
    }

    const slidesMappedToNavItems = () =>
      slides.map((slide, index) => {
        const isSelected = selectedSlideIndex === index
        const fontColor = isSelected ? 'text-red' : 'text-smallLightGray'

        return (
          <div key={slide.name} className="flex items-center mb-4 justify-end">
            <button
              className={`${fontColor} transition-all ease-in-out duration-500 text-right text-xs leading-18px`}
              onClick={() => handleSlideChange(index)}
              type="button"
            >
              {slide.name}
            </button>
            {Indicator(isSelected)}
          </div>
        )
      })

    return (
      <div ref={ref} className={`${className}`}>
        {slidesMappedToNavItems()}
      </div>
    )
  }
)

SlidesNavigation.defaultProps = {
  className: '',
}

export default SlidesNavigation
